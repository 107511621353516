import '../styles/index.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { useEffect } from 'react'
import { ConfigProvider } from '@lockerpm/design'
import { useRouter } from 'next/router'
import { appWithTranslation } from 'next-i18next'
import Script from 'next/script'
import Head from 'next/head'
import TagManager from 'react-gtm-module'
import { setCookie, getCookie } from 'cookies-next'

import ENDPOINT from '@/config/endpoint'
import enUS from '@lockerpm/design/locale/en_US'
import viVN from '@lockerpm/design/locale/vi_VN'

function App({ Component, pageProps }) {
  const router = useRouter()
  const { locale } = router
  const isDev = process.env.NODE_ENV === 'development'

  // Setup GTM
  useEffect(() => {
    if (!isDev) {
      TagManager.initialize({ gtmId: 'GTM-K5Q6595' })
    }
  }, [])

  // Setup Chatwoot
  useEffect(() => {
    if (!window) {
      return
    }
    if (window.$chatwoot) {
      window.$chatwoot.setLocale(locale)
    } else {
      if (window.chatwootSettings) {
        window.chatwootSettings.locale = locale
      } else {
        window.chatwootSettings = {
          locale,
          baseDomain: '.locker.io',
          // darkMode: 'auto'
        }
      }
    }
  }, [locale])

  // Add locale when navigating to vault
  // Auto set trial plan to premium when register locker pm
  useEffect(() => {
    const handler = (event) => {
      let target = event.target
      while (target && target.tagName !== 'A') {
        target = target.parentElement
      }
      const href = target?.href || ''

      // Set trial plan to cookie
      if ([ENDPOINT.ID_PM_REGISTER, ENDPOINT.ID_GENERAL_REGISTER].includes(href)) {
        // Keep trial plan if requested (this should only happen inside iframe, but who knows?)
        if (window?.sessionStorage?.getItem('keepTrialPlan')) {
          window.sessionStorage.removeItem('keepTrialPlan')
        } else {
          // Else default trial plan is premium
          setCookie('trial_plan', 'pm_premium', { domain: '.locker.io', path: '/' })
        }
      }

      // Add lang to query
      const shouldAddLang = [ENDPOINT.PM_BASE_URL, ENDPOINT.SM_BASE_URL, ENDPOINT.ID_BASE_URL].some(
        (i) => href.startsWith(i)
      )
      if (shouldAddLang) {
        event.preventDefault()
        const url = new URL(target.href)
        url.searchParams.delete('lang')
        url.searchParams.append('lang', locale)
        window.open(url.toString(), event.target.target)
        return
      }
    }
    document.body.addEventListener('click', handler)
    return () => {
      document.body.removeEventListener('click', handler)
    }
  }, [locale])

  return (
    <>
      <ConfigProvider
        locale={locale === 'vi' ? viVN : enUS}
        theme={{
          hashed: false,
          token: {
            colorPrimary: '#268334',
          },
        }}
      >
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=5, viewport-fit=cover"
          />
        </Head>
        <Component {...pageProps} />

        {/* Chatwoot */}
        <Script
          id="chatwoot"
          async
          defer
          src="https://chat.cystack.org/packs/js/sdk.js"
          onLoad={() => {
            window.chatwootSDK.run({
              websiteToken: '9cv1u2gYqmg7VWqmj95orwAD',
              baseUrl: 'https://chat.cystack.org',
            })
          }}
        />
        {/* Chatwoot end */}

        {/* Tracking */}
        <Script id="tracking" async defer src="/js/visit.min.js" />
        {/* Tracking end */}
      </ConfigProvider>
    </>
  )
}

export default appWithTranslation(App)
