const BACKEND_URL = process.env.NEXT_PUBLIC_API_URL
const NOTION_URL = process.env.NEXT_PUBLIC_NOTION_URL
const PM_BASE_URL = process.env.NEXT_PUBLIC_PM_BASE_URL || 'https://passwords.locker.io'
const SM_BASE_URL = process.env.NEXT_PUBLIC_SM_BASE_URL || 'https://secrets.locker.io'
const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL || 'https://api.locker.io/v3'
const BLOG_API_URL =
  process.env.NEXT_PUBLIC_BLOG_API_URL || 'https://blog.cystack.org/locker/wp-json/wp/v2'
const ID_BASE_URL = process.env.NEXT_PUBLIC_ID_BASE_URL || 'https://id.locker.io'
const OLD_URL = process.env.NEXT_PUBLIC_OLD_URL || 'https://old.locker.io'

const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT || ''
const ID_GENERAL_LOGIN = `${ID_BASE_URL}/login/explore?ENVIRONMENT=${ENVIRONMENT}`
const ID_GENERAL_REGISTER = `${ID_BASE_URL}/login/explore?ENVIRONMENT=${ENVIRONMENT}&next=register`
const ID_PM_LOGIN = `${ID_BASE_URL}/login?SERVICE_URL=%2F&SERVICE_SCOPE=pwdmanager&ENVIRONMENT=${ENVIRONMENT}`
const ID_PM_REGISTER = `${ID_BASE_URL}/register?SERVICE_URL=%2F&SERVICE_SCOPE=pwdmanager&ENVIRONMENT=${ENVIRONMENT}`
const ID_SM_LOGIN = `${ID_BASE_URL}/login?SERVICE_URL=%2F&SERVICE_SCOPE=secret&ENVIRONMENT=${ENVIRONMENT}`
const ID_SM_REGISTER = `${ID_BASE_URL}/register?SERVICE_URL=%2F&SERVICE_SCOPE=secret&ENVIRONMENT=${ENVIRONMENT}`

export default {
  BACKEND_URL,
  NOTION_URL,
  PM_BASE_URL,
  SM_BASE_URL,
  BASE_API_URL,
  BLOG_API_URL,
  ID_BASE_URL,
  ID_GENERAL_LOGIN,
  ID_GENERAL_REGISTER,
  ID_PM_LOGIN,
  ID_PM_REGISTER,
  ID_SM_LOGIN,
  ID_SM_REGISTER,
  OLD_URL,
  ENVIRONMENT,
}
